import {pageBuilderService} from "@/services/pageBuilder.service";

export const pageBuilder = {
    namespaced: true,
    state: {
        screen: [],
        node: [],
        field: [],
        widget: [],
        group: [],
        preset: [],
        process: [],
        function: [],
        collection: [],
        wrapper: [],
        TYPE_REFS: {},
        currentNode: null
    },
    getters: {
        screen: state => state.screen,
        node: state => state.nodes,
        field: state => state.field,
        widget: state => state.widget,
        group: state => state.group,
        preset: state => state.preset,
        process: state => state.process,
        function: state => state.function,
        collection: state => state.collection,
        wrapper: state => state.wrapper,
        currentNode: state =>state.currentNode,
    },
    mutations: {
        getElementsByType(state, response) {
            state[state.TYPE_REFS.find(type => type.id === response.id).prefixName] = response.data;
        },
        getTypesDictionary(state, value) {
            state.TYPE_REFS = value;
        },
        getNode(state, node) {
          state.currentNode = node
        }
    },
    actions: {
        async getTypesDictionary({commit}) {
          const response = await pageBuilderService.getTypesDictionary();
          commit('getTypesDictionary', response.response);
        },
        async getElementsByType({commit}, nodeType) {
            const response = await pageBuilderService.getElementsByType(nodeType);
            commit('getElementsByType', {id: nodeType, data: response.response});
        },
        async createNode({commit}, payload) {
            return await pageBuilderService.createNode(payload);
        },
        async changeNode({commit, state}, nodeInfo) {
            nodeInfo.nodePart = {
                ...nodeInfo.nodePart,
                nodeType: state.TYPE_REFS.find(type => type.prefixName === nodeInfo.nodePart.nodeType).id
            };
            if (nodeInfo.nodePart?.nodeFields) {
                nodeInfo.nodePart = {...nodeInfo.nodePart, childrenNodes: nodeInfo.nodePart.nodeFields};
                delete nodeInfo.nodePart.nodeFields;
            }
            return await pageBuilderService.changeNode(nodeInfo.partId, nodeInfo.nodePart);
        },
        async getNode({commit}, db_id) {
          const response = await pageBuilderService.getNode(db_id);
          commit('getNode', response.response);
        }
    }
}
