import { render, staticRenderFns } from "./FrameNode.vue?vue&type=template&id=25d68630&scoped=true"
import script from "./FrameNode.vue?vue&type=script&lang=js"
export * from "./FrameNode.vue?vue&type=script&lang=js"
import style0 from "./FrameNode.vue?vue&type=style&index=0&id=25d68630&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d68630",
  null
  
)

export default component.exports