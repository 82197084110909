import Vue from "vue";
import VueI18n from "vue-i18n";
import {DEFAULT_LANGUAGE} from '../constants/language'

Vue.use(VueI18n);

const messages = {
  ru: {
    delete: 'Удалить',
    cancel: 'Отмена',
    save: 'Сохранить',
    create: 'Создать',
    close: 'Закрыть',
    reset: 'Сбросить',
    download: 'Скачать',
    upload: 'Загрузить',
    edit: 'Редактировать',
    exit: 'Выход',
    helloScreen: {
      welcome: 'Добро пожаловать!',
      joinInAdmin: 'Войти в админ-панель',
      welcomeBack: 'С возвращением',
      loginAndPass: 'Введите ваш логин и пароль, чтобы войти',
      login: 'Логин',
      password: 'Пароль',
      signIn: 'Войти',
    },
    menu: {
      processes: 'Процессы',
      widgets: 'Виджеты',
      multiLang: 'Мультиязычность',
      settings: 'Настройки',
    },
    settings: {
      basic: 'Основное',
      images: 'Изображения',
      colors: 'Цвета',
      projectName: 'Название проекта',
      plProject: 'Введите название проекта',
      companyName: 'Название компании',
      plCompany: 'Введите название компании',
      plSelectFile: 'Выберите файл',
      logo: 'Логотип',
      favicon: 'Фавикон',
      preloaderColor: 'Цвет прелоадер',
      timeResendCode: 'Время переотправки кода в секундах',
      plTimeResendCode: 'Введите время',
      globalUrl: 'Урла приложения',
      plGlobalUrl: 'Введите url',
      imageUrl: 'Путь к папке с изображениями',
      plImageUrl: 'Введите url',
      mainColor: 'Основной цвет',
      activeColor: 'Активный цвет',
      bgColor: 'Цвет фона',

      resetSetting: 'Сброс настроек проекта',
      confResetSetting: 'Вы уверены, что хотите поставить дефолтные настройки проекта?',

      labelFontSize: 'Размер текста',
      labelFontFamily: 'Шрифт текста',
      labelColorText: 'Цвет текста',
      labelFontHeadline: 'Шрифт заголовков',
      labelSizeHeadline: 'Размер заголовков',
    },
    process: {
      process: 'Процесс',
      processName: 'Название процесса',
      editProcess: 'Редактировать процесс',
      createProcess: 'Создать процесс',
      delete: 'Удалить',
      cancel: 'Отмена',
      save: 'Сохранить',
      create: 'Создать',
      requiredField: 'Обязательное поле',
      plProcessName: 'Введите название процесса',
      deleteProcess: 'Удаление процесса',
      confDeleteProcess: 'Вы уверены, что хотите удалить процесс?',
      processTheme: 'Тема процесса',
      light: 'Cветлая',
      dark: 'Темная',

      unAvailablePreset: 'Пресет недоступен',

      warnNoRules: 'Отсутствуют правила между:',
      errorCustomNode: 'На канвасе присутсвуют кастомные ноды:',
      errorStartNode: 'Количество начальных нод больше одной, проверьте связи',
    },
    modal: {
      cancel: 'Отмена',
      yes: 'Да',
      clickToSelectImg: 'Нажмите чтобы выбрать изображение',
      selectImg: 'Выбрать изображение',
      selectMediaFromList: 'Выберите изображение из представленного списка или загрузите новое',
      uploadNewImg: 'Загрузить новое изображение',
    },
    node: {
      performanceNode: 'Характеристики ноды',
      presets: 'Пресеты',
      preset: 'Пресет',
      screens: 'Экраны',
      conditions: {
        title: 'Условные переходы',
        empty: 'Условия остутствуют',
        internal: {
          title: 'Внутренние',
          empty: 'Внутренние преходы остутствуют'
        },
        external: {
          title: 'Внешние',
          empty: 'Внешние преходы остутствуют'
        },
      },
      actions: {
          title: 'Действия',
          from: 'из',
          to: 'в',
          on: 'при ',
          do: ' делать ',
      },
      intoPreset: {
        addPreset: 'Добавить пресет',
        delAllRules: 'Удалить все правила у пресета',
        canvasName: 'Имя на канвасе',
        plNameField: 'Введите имя',
        withoutPreset: 'Нода без пресетов',
        defaultPreset: 'Дефолтный пресет',
      },
      intoCondition: {
        selectCondition: 'Выберите условие',
        showRule: 'Раскрыть правило',
        delRule: 'Удалить правило',
        condition: 'Условие',
        service: 'Сервис',
        action: 'Действие',
        request: 'Запрос',
        answer: 'Ответ',
        confDelete: 'Подтверждение удаления',
        deleteRule: 'Вы уверены, что хотите удалить?',
        deleteCondition: 'Удалить условие',
        confDeleteRule: 'Вы уверены, что хотите удалить условие?',
        confDeleteTrans: 'Вы уверены, что хотите удалить переход?',
        // : '',
      }
    },
    intoWidget: {
      performance: 'Характеристики',
      performanceWidget: 'Характеристики виджета',
    },
    gradientColor: {
      color: 'Цвет',
      degree: 'Градус',
      setBgColor: 'Задать цвет фона',
      removeBgColor: 'Убрать цвет фона', //Remove background color
      mono: 'моно',
      gradient: 'градиент',
      bgColor: 'Цвет фона',
      // : '',
    },
    multilang: {
      error: 'Что-то пошло не так',
      load: 'Загрузка...',
      enterContent: 'Введите содержимое',
    },
    screenEditor: {
      text: 'Текст',
      icon: 'Иконка',
      label: 'Лейбл',
      countSymbol: 'Количество символов',
      countCells: 'Количество ячеек',
      mask: 'Маска',
      placeholder: 'Плейсхолдер',
      position: 'Расположение',
      image: 'Изображение',
      fontSize: 'Размер шрифта',
      underline: 'Подчеркивание',
      bgColor: 'Цвет фона',
      selectColor: 'Выбор цвета',
      align: {
        center: 'Центр',
        left: 'Лево',
        right: 'Право',
      }
    }
  },


  en: {
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    close: 'Сlose',
    reset: 'Reset',
    download: 'Download',
    upload: 'Upload',
    edit: 'Edit',
    exit: 'Exit',
    helloScreen: {
      welcome: 'Welcome!',
      joinInAdmin: 'Join in admin-panel',
      welcomeBack: 'Welcome back',
      loginAndPass: 'Enter your login and password to log in',
      login: 'Login',
      password: 'Password',
      signIn: 'Sign in',
    },
    menu: {
      processes: 'Processes', 
      widgets: 'Widgets',
      multiLang: 'Multi-language',
      settings: 'Settings',
    },
    settings: {
      basic: 'Basic',
      images: 'Images',
      colors: 'Colors',
      projectName: 'Project name',
      plProject: 'Enter project name',
      companyName: 'Company name',
      plCompany: 'Enter company name',
      plSelectFile: 'Select file',
      logo: 'Logo',
      favicon: 'Favicon',
      preloaderColor: 'Preloader color',
      timeResendCode: 'Code retransmission time in seconds',
      plTimeResendCode: 'Enter time',
      globalUrl: 'Global url',
      plGlobalUrl: 'Enter url',
      imageUrl: 'Path to the folder with images',
      plImageUrl: 'Enter url',
      mainColor: 'Main color',
      activeColor: 'Active color',
      bgColor: 'Background color',

      resetSetting: 'Reset project settings',
      confResetSetting: 'Are you sure you want to set the default project settings?',

      labelFontSize: 'Font size',
      labelFontFamily: 'Font family',
      labelColorText: 'Color text',
      labelFontHeadline: 'Font headline',
      labelSizeHeadline: 'Size headline',
    },
    process: {
      process: 'Process',
      processName: 'Process name',
      editProcess: 'Edit process',
      createProcess: 'Create process',
      delete: 'Delete',
      cancel: 'Cancel',
      save: 'Save',
      create: 'Create',
      requiredField: 'Required field',
      plProcessName: 'Enter process name',
      deleteProcess: 'Delete process',
      confDeleteProcess: 'Are you sure you want to delete the process?',
      processTheme: 'Process theme',
      light: 'Light',
      dark: 'Dark',

      unAvailablePreset: 'Unavailable preset',

      warnNoRules: 'There are no rules between:',
      errorCustomNode: 'There are custom nodes on the canvas:',
      errorStartNode: 'The number of starting nodes is more than one, check the connections',
    },
    modal: {
      cancel: 'Cancel',
      yes: 'Yes',
      clickToSelectImg: 'Click to select image',
      selectImg: 'Select image',
      selectMediaFromList: 'Select an image from the list provided or upload a new one',
      uploadNewImg: 'Upload new image',
    },
    node: {
      performanceNode: 'Performance node',
      presets: 'Presets',
      preset: 'Preset',
      screens: 'Screens',
      conditions: {
        title: 'Transition conditions',
        empty: 'Conditions is empty',
        internal: {
          title: 'Internal',
          empty: 'Internal transition is empty'
        },
        external: {
          title: 'External',
          empty: 'External transition is empty'
        },
      },
      actions: {
        title: 'Actions',
        from: 'from',
        to: 'to',
        on: 'on ',
        do: ' do ',
      },
      intoPreset: {
        addPreset: 'Add preset',
        delAllRules: 'Delete all rules from a preset',
        canvasName: 'Name on canvas',
        plNameField: 'Enter name',
        withoutPreset: 'Node without presets',
        defaultPreset: 'Default preset',
      },
      intoCondition: {
        selectCondition: 'Select condition',
        showRule: 'Show rule',
        delRule: 'Delete rule',
        condition: 'Condition',
        service: 'Service',
        action: 'Action',
        request: 'Request',
        answer: 'Answer',
        confDelete: 'Deletion confirmation',
        deleteRule: 'Are you sure you want to delete?',
        deleteCondition: 'Delete condition',
        confDeleteRule: 'Are you sure you want to delete the condition?',
        confDeleteTrans: 'Are you sure you want to delete the transition?',
        // : '',
      }
    },
    intoWidget: {
      performance: 'Performance',
      performanceWidget: 'Widget performance',
    },
    gradientColor: {
      color: 'Color',
      degree: 'Degree',
      setBgColor: 'Set background color',
      removeBgColor: 'Remove background color',
      mono: 'mono',
      gradient: 'gradient',
      bgColor: 'Background color',
    },
    multilang: {
      error: 'Something went wrong',
      load: 'loading...',
      enterContent: 'Enter content',
    },
    screenEditor: {
      text: 'Text',
      icon: 'Icon',
      label: 'Label',
      countSymbol: 'Count symbol',
      countCells: 'Count cells',
      mask: 'Mask',
      placeholder: 'Placeholder',
      position: 'Position',
      image: 'Image',
      fontSize: 'Font size',
      underline: 'Underline',
      bgColor: 'background color',
      selectColor: 'Select color',
      align: {
        center: 'Center',
        left: 'Left',
        right: 'Right',
      }
    }
  }
};

const i18n = new VueI18n({
  locale: localStorage.systemLang ?? DEFAULT_LANGUAGE,
  messages
});

export default i18n