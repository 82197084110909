import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";

import PortalVue from "portal-vue"
import uikitBpm from 'uikit-bpm';
import graphEditorDev from "Graph-Editor-dev"
import 'Graph-Editor-dev/GraphEditor.css';
import components from "@/components/UI"
import "@/styles/main.scss"
import '!!vue-style-loader!css-loader!uikit-bpm/uikit-bpm.css'

// colorAdmin
Vue.prototype.$colorAdmin = process.env.VUE_APP_COLOR_ADMIN ?? 'linear-gradient(to right, #7C4DFF, #B388FF)';

Vue.config.devtools = true;
Vue.config.productionTip = false;

components.forEach(component => {
  Vue.component(component.name, component);
})

Vue.use(PortalVue);
Vue.use(uikitBpm);
Vue.use(graphEditorDev);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
