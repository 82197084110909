import { defaultApiInstance } from "@/api";

export const processesService =  {
  getProcesses,
  getProcess,
  saveProcess,
  updateNameProcess,
  addProcess,
  deleteProcess,

  getActionsProcess,
  saveActionsProcess,
  deleteActionsProcess,
  deleteConnection
}

async function getProcesses() {
  return await defaultApiInstance.get('/processes');
}

async function getProcess(id) {
  return await defaultApiInstance.get(`/processes/${id}`);
}

async function deleteProcess(id) {
  return await defaultApiInstance.delete(`/processes/${id}`);
}

async function saveProcess(process, id) {
  // availableNodes is useless (50/50)
  return await defaultApiInstance.patch(`processes/${id}?new_process_format=true`, { process_n8n: { ...process, availableNodes: {} } })
}

async function updateNameProcess(name, data, id) {
  return await defaultApiInstance.patch(`processes/${id}?new_process_format=true`, {process_name: name, process_n8n: data})
}

async function addProcess(process_settings) {
  return await defaultApiInstance.post(`processes`, process_settings);
}

async function getActionsProcess({processId, elementId, nodeFromId, nodeToId}) {
  const process = processId ? `processId=${processId}&` : ''
  const element = elementId ? `elementId=${elementId}&` : ''
  const nodeFrom = nodeFromId ? `nodeFromId=${nodeFromId}&` : ''
  const nodeTo = nodeToId ? `nodeToId=${nodeToId}` : ''

  return await defaultApiInstance.get(`/actionsProcesses?${process}${element}${nodeFrom}${nodeTo}`)
}

async function saveActionsProcess(condition) {
  return await defaultApiInstance.post('actionsProcesses', condition)
}

async function deleteActionsProcess({processId, actionProcessId}) {
  return await defaultApiInstance.delete(`actionsProcesses?processId=${processId}&id=${actionProcessId}`)
}

async function deleteConnection(processId, {nodeFrom, nodeTo}) {
  return await defaultApiInstance.delete(`actionsProcesses?processId=${processId}&nodeFromId=${nodeFrom}&nodeToId=${nodeTo}`)
}
