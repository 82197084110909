import { defaultApiInstance } from "@/api";
import {node} from "@/store/modules/node.module";

export const pageBuilderService = {
    getElementsByType,
    getNode,
    createNode,
    changeNode,
    getTypesDictionary
}

/**
 * @param {Number} nodeTypeId - type id for by which we can get elements for this type
 * */
async function getElementsByType(nodeTypeId) {
    return defaultApiInstance.get(`/editor/nodes?nodeType=${nodeTypeId}`);
}

async function getNode(nodeId) {
    return defaultApiInstance.get(`/editor/nodes/${nodeId}`);
}

async function changeNode(partId, nodePart) {
    return defaultApiInstance.patch(`/editor/nodes/${partId}`, nodePart);
}

async function getTypesDictionary() {
    return defaultApiInstance.get('editor/nodes/types');
}

async function createNode(payload) {
    return defaultApiInstance.post('/editor/nodes', payload);
}
