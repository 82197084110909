export default {
  computed: {
    actionsProcess: {
      cache: false,
      get(){
        return this.$store.getters['processes/actionsProcess']
      }
    },  

    currentNode() {
      return this.$store.getters['node/currentNode']
    },

    currentPreset: {
      cache: false,
      get(){
        return this.$store.getters['node/currentPreset'].id
      }
    },

    availableScreens() {
      return this.$store.getters['node/availableScreens']
    }, 
    
    screens() {
      return this.$store.getters['node/settings'].screens
    },

    toCurrentScreen(){ 
      return this.actionsProcess.filter(action => {
        return this.screens
          .map(screen => screen.id === action.nodeFromId)[0]
        })
    },

    outerConnections() {
      return this.$store.getters['node/outerConnections']
    },

    actionForElement(){
      return [
        {name: 'Action', type: 'actionId', item: this.getActions}, 
        {name: 'Event', type: 'eventId', item: this.getEvents}, 
        {name: 'Go to', type: 'goTo', item: this.outerConnectionsInfo}, 
        {name: 'Screen', type: 'screen', item: this.availableScreensAtNode}, 
        {name: 'Order', type: 'order', item: [{name:1, id:1},{name:2, id:2},{name:3, id:3},{name:4, id:4},]}
      ]
    },

    outerConnectionsInfo() {
      const context = this.$store.getters['processes/localContext'] ?? this.$store.getters['processes/editorData']

      return Object.values(context.nodes)
        .filter(node => this.outerConnections.includes(node.db_id))
        .map(node => ({id: node.db_id, name: node.displayName}))
        .map(node => {
          if(node.id === this.currentNode.db_id) node.name = 'Current node'
          return node
        }) 
    },

    getActions(){
      const actions = this.$store.getters['actions/actions']
      actions.map(action => {
        action.name = action.actionName
        return action
      })

      return actions
    },

    getEvents(){
      const events = this.$store.getters['events/events']
      events.map(event => {
        event.name = event.eventName
        return event
      })

      return events
    },

    availableScreensAtNode: {
      cache: false,
      get(){
        return this.actionForElementData?.goTo 
          ? this.availableScreens 
            ? this.availableScreens.filter(screen => screen.nodeId === this.actionForElementData.goTo)
            : null
          : null
      }
    },

    currentScreenId() {
      return this.$store.getters['node/currentScreenId']
    },
  },
  methods: {

  }
}