import { render, staticRenderFns } from "./MainProps.vue?vue&type=template&id=5b37d548&scoped=true"
import script from "./MainProps.vue?vue&type=script&lang=js"
export * from "./MainProps.vue?vue&type=script&lang=js"
import style0 from "./MainProps.vue?vue&type=style&index=0&id=5b37d548&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b37d548",
  null
  
)

export default component.exports