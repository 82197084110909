<template>
  <SpinnerLoader
    v-if="loading"
    color="deep-purple accent-2"
  />
  <div class="screen-edit d-flex flex-grow-1" v-else-if="currentScreen">
    <v-col class="screen-edit__general pa-4 d-flex" style="height: calc(100vh - 64px)">
      <v-btn
        class="flex-grow-0"
        icon
        @click="close"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div class="screen-edit__content d-flex align-center justify-center flex-grow-1">
        <NodeScreen
          :setting="currentScreen"
          :active-actions="false"
        />
      </div>
    </v-col>

    <v-col class="screen-edit__characteristics pa-0">
      <h2 class="text-h5 mx-6 my-4">{{ $t('intoWidget.performance') }}: {{ currentScreen.name }}</h2>
      <NodeScreenEdit
        @save="saveScreenSettings"
        @reset="resetScreenSettings"
        @showPopupBg="showPopupBg"
      />
      <!-- modal window -->
      <portal-target v-if="isShowPopupBg" class="screen-edit__portal-target" name="action_for_element" :style="heightComponent" />
    </v-col>
  </div>
</template>

<script>
import NodeScreen from "@/components/nodes/nodeScreens/NodeScreen.vue";
import NodeScreenEdit from "@/components/nodes/nodeScreens/settings/NodeScreenEdit.vue";
// import {refactorFieldToView} from "@/utils/utils-functions";

export default {
  name: 'ScreenEdit',
  components: {
    NodeScreenEdit,
    NodeScreen,
  },
  data () {
    return {
      previousPreview: null,
      currentScreen: null,
      previousCurrentScreen: null,
      loading: false,
      isShowPopupBg: false,
    }
  },
  computed: {
    screenId () {
     return this.$store.getters["node/currentScreenId"];
    },
    dialog () {
      return !!this.screenId;
    },
    heightComponent: {
      cache: false,
      get(){
        const height = this.$store.getters['node/heightScreenEditComponent']
        return height ? `height:${height}px` : 'height:100%'
      }
    }
  },
  async created () {
    await this.getCurrentScreen(this.screenId);
  },
  methods: {
    close () {
      this.$store.dispatch('node/setCurrentField', null);
      this.$store.dispatch('node/setCurrentScreenId', null);
    },
    async getCurrentScreen (id) {
      this.loading = true;
      await this.$store.dispatch('node/setEditScreen', id);
      this.previousCurrentScreen = JSON.parse(JSON.stringify(this.$store.getters["node/currentScreen"]));
      this.currentScreen = this.$store.getters["node/currentScreen"];
      this.loading = false;
    },
    async resetScreenSettings (rerender) {
      await this.getCurrentScreen(this.screenId);
      this.$store.dispatch('node/setCurrentField', null);
      rerender();
    },

    saveScreenSettings (currentScreen, textStyles) {
      // dispatch save
      this.$store.dispatch("node/saveScreenSettings", currentScreen);
      // save text styles in local store
      Object.keys(textStyles).forEach(styleKey => {
        localStorage.setItem(styleKey, textStyles[styleKey]);
      })
      this.close();
    },

    showPopupBg(){
      this.isShowPopupBg = !this.isShowPopupBg
    }
  }
}
</script>

<style scoped lang="scss">
.screen-edit {
  &__characteristics {
    background-color: #ffffff;
    box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
    position: relative;
    overflow-y: scroll;
  }

  &__portal-target {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(#212121, 0.4);
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
