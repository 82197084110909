export default {
  data() {
    return {
      selectedPreset: null,
    }
  },
  computed: {
    presets() {
      const presets = this.$store.getters["node/presets"];
      return presets ?? false
    },
    nodeId() {
      return this.$store.getters["node/nodeId"];
    },
    nodeType() {
      return this.$store.getters["node/nodeType"];
    },
  },
  created() {
    if(!this.presets) return
    const presetsId = this.presets.map(item => item.id)
    const idInLocalStorage = Number(localStorage.getItem(`preset-for-node-${this.nodeId}`))

    if (localStorage.getItem(`preset-for-node-${this.nodeId}`) && presetsId.includes(idInLocalStorage)) {
      this.presets.forEach((preset) => {
        if (String(preset.id) === localStorage.getItem(`preset-for-node-${this.nodeId}`)) {
          this.selectedPreset = preset.id;
          this.$store.dispatch('node/setCurrentPresetId', preset.id)
        } 
      })
    } else {
      this.$store.dispatch('node/setCurrentPresetId', this.presets[0].id)
    }
    this.$forceUpdate();
  },
  methods: {
    async getNodePreset(preset) {
      let connections = this.$store.getters["node/connections"]
      this.$store.commit('node/setNodeEditLoading', true);
      localStorage.setItem(`preset-for-node-${this.nodeId}`, preset);
      await this.$store.dispatch("node/setSettings", {id: this.nodeId, preset, type: this.nodeType, connections})
      this.$store.commit('node/setNodeEditLoading', false);
    }
  }
}