<template>
    <div id="nodePreset" ref="nodePreset"> 
      <p v-if="!transitions" class="empty-transition">{{ $t('node.conditions.empty') }}</p>
      <template v-else>

        <div class="btn-toggle">
          <button 
            @click="setTransitionType('internal')" 
            class="btn-toggle__type" 
            :class="{'active': internalType}"
          >
            {{ $t('node.conditions.internal.title') }}
          </button>
          <button 
            @click="setTransitionType('external')" 
            class="btn-toggle__type" 
            :class="{'active': !internalType}"
          >
            
            {{ $t('node.conditions.external.title') }}
          </button>
        </div>

        <template v-if="internalType">
          <p v-if="!transitions.internal.length" class="empty-transition">{{ $t('node.conditions.internal.empty') }}</p>
          <template v-else>
            <TransitionStructure 
              v-for="transition,i in transitions.internal" :key="transition.elementId"
              class="mt-5"
              :transition="transition"
              :last="transitions.internal.length === ++i"
              type="internal"
              :oneElement="transition.oneElement"
            />
          </template>
        </template>

        <template v-else>
          <p v-if="!transitions.external.length" class="empty-transition">{{ $t('node.conditions.external.empty') }}</p>
          <template v-else>
            <TransitionStructure 
              v-for="transition,i in transitions.external" :key="transition.elementId"
              class="mt-5"
              :transition="transition"
              :last="transitions.external.length === ++i"
              type="external"
              :oneElement="transition.oneElement"
              :nodeTitle="settings.title"
            />
          </template>
        </template>

      </template>
    </div>
  </template>
  
  <script>
  import popupPosition from "@/mixins/popupPosition";
  import TransitionStructure from "@/components/nodes/nodeTransitions/TransitionStructure.vue";
  import conditionsMixin from '@/mixins/conditionsMixin.js'
  export default {
      name: 'NodeTransitions',
      mixins: [popupPosition, conditionsMixin],
      components: {
        TransitionStructure,
     },
     data(){
      return{
        transitionsType: 'internal'
      } 
     },
     computed: {
      transitions: {
        cache: false,
        get(){
          let internal = []
          let external = []
          const internalEl = []
          const externalEl = []

          const currentNodeFromEditor = this.$store.getters['pageBuilder/currentNode']
          const isHavePresets = currentNodeFromEditor?.presets?.length > 1
          const currentPreset = isHavePresets
            ? currentNodeFromEditor.presets.find(item => item.id === this.currentPreset)
            : currentNodeFromEditor

          // принадлежат открывшейся ноде и совпадают с ее экранами
          const screenOfCurrentNode = this.availableScreens.filter(screen => screen.nodeId === this.currentNode.db_id)
          
          if(!this.screens) return
          const screensId = this.screens.map(item => item.id)

          // actions - отфильтрованные экшины для текущего экрана
          const actions = this.actionsProcess.filter(action => {
            return screensId.includes(action.nodeFromId)
          })
          if(!actions || !actions.length) return

          this.actionsProcess
            .filter(action => {
              return this.screens?.map(screen => screen.id)?.includes(action.nodeFromId)
            })
            .map(node => {
              if(!currentPreset?.screens) return

              const screen = currentPreset?.screens.find(screen => screen?.id === node.nodeFromId)
              const element = node.elementId
                ? screen.nodeFields.find(nodeField => nodeField?.id === node.elementId)
                : null

              const fromInfo = {screenName: screen?.name, elementName: element?.name}
              const screensId = screenOfCurrentNode.map(node => node.screenId)

              if(screensId.includes(node.nodeToId) && node.eventId) internal.push({...node, ...fromInfo})
              else external.push({...node, ...fromInfo})
            })

          internal = internal.map(action => {
            internalEl.push(action.elementId)
            const oneElement = internalEl.filter(item => item === action.elementId).length > 1

            return {...action, 
              screenId: action.nodeToId,
              nodeName: this.currentNode.name, 
              nodeId: this.currentNode.db_id, 
              presetName: isHavePresets ? currentPreset.name : null, 
              presetId: isHavePresets ? currentPreset.id : null,
              oneElement
            }
          }).sort((a, b) => a.elementId - b.elementId)

          external = external.map(action => {
            const screen = this.availableScreens.find(node => node.screenId === action.nodeToId)

            externalEl.push(action.elementId)
            const oneElement = externalEl.filter(item => item === action.elementId).length > 1

            return {...action, ...screen, oneElement}
          }).sort((a, b) => a.elementId - b.elementId)

          return {internal, external}
        }
      },
      internalType(){
        return this.transitionsType === 'internal'
      },
      isDefaultTransitions(){
        return this.transitions.external
          .find(transition => !transition.elementId)
      },
      settings() {
        return this.$store.getters["node/settings"]
      },
      getContext() {
        return this.$store.getters['processes/getContext']
      },
    },
    methods: {
      setTransitionType(type){
        this.transitionsType = type
      }
    }
  }
  </script>

<style lang="scss" scoped>
.btn-toggle{
  border-radius: 12px;
  background: #F6F9FC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 6px;
  gap: 4px;
  display: flex;

  &__type {
    padding: 4px;
    width: 50%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700; 
    color: #172B4D;

    &.active{
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background: #FFF;
    }
  }
}
.empty-transition{
  background: #F5F7FB;
  text-align: center;
  border-radius: 12px;
  padding: 10px 16px;
  color: #5A717C;
  margin-top: 16px;
}

</style>