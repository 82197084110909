<template>
  <div>
    <div v-if="bg" class="bg_popup" ></div>
    <cardPopup 
      v-if="!(isAddAction || isAddEvent)"
      :title="defaultTrans ? 'Default transition' : `Edit action for element '${rule.name}'`" 
      :style="popupPositionLocal"
      :isActiveBtn="permissionSave"
      class="popup"
      id="cardPopupRule"
      @passiveBtn="cancel" @activeBtn="save"
    >

      <div v-for="item in actionForElement" :key="item.type" class="mb-2">

        <div 
          v-if="!((item.type === 'eventId' || item.type === 'order') && defaultTrans)"
        >
          <label class="mr-2">{{ item.name }}</label>
          <!-- <button v-if="item.type === 'actionId'" @click="addAction">+</button>
          <button v-if="item.type === 'eventId'" @click="addEvent">+</button> -->

          <v-autocomplete
            :items="item.item"
            v-model="actionForElementData[item.type]"
            :item-value="item.type === 'screen' ? 'screenId' : 'id'"
            :item-text="item.type === 'screen' ? 'screenName' : 'name'"
            outlined
            placeholder="Select"
            hide-details
            class="mt-1"
            />
        </div>
      </div>

      <label>Conditions</label>
      <ConditionSettings
        class="my-4"
        :transition="actionForElementData?.condition"
        @updateExpression="updateExpression"
      />
    </cardPopup>

    <cardPopup
      v-if="isAddAction"
      class="popup"
      :style="popupPositionLocal"
      title="New action" 
      @activeBtn="save2"
      @passiveBtn="addAction" 
    >
        <v-text-field label="Name" outlined />
    </cardPopup>

    <cardPopup
      v-if="isAddEvent"
      class="popup"
      :style="popupPositionLocal"
      title="New event" 
      @activeBtn="save3"
      @passiveBtn="addEvent" 
    >
      <v-text-field label="Name" outlined/>
      <v-text-field label="Param 1" outlined />
      <v-text-field label="Param 2" outlined />
    </cardPopup>

  </div>
</template>

<script>
import cardPopup from '../../UI/cardPopup.vue'
import ConditionSettings from './ConditionSettings.vue'

import conditionsMixin from '../../../mixins/conditionsMixin.js'
import popupPosition from '../../../mixins/popupPosition'
export default{
  name: 'popupConditions',
  props: {
    rule: {
      type: Object
    },
    position: {
      type: Number
    },
    // for integrate for NodeScreenEdit
    isScreenEdit: {
      type: Boolean
    },
    bg: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: true
    },
    defaultTrans: {
      type: Boolean,
      default: false
    }
  },
  components: {
    cardPopup,
    ConditionSettings
  },  
  mixins: [conditionsMixin, popupPosition],
  data () {
    return {
      isShowAddAction: false,
      isAddAction: false,
      isAddEvent: false,

      actionForElementData: {
        actionId: null,
        eventId: null,
        goTo: null,
        screen: null,
        order: null,
        condition: null
      },
      positionLocal: 0,
      elementId: null,
    }
  },
  computed: {
    popupPositionLocal(){
      /* eslint-disable */

      const offset = this.positionLocal ? this.positionLocal : this.position + this.positionPopup 
      this.positionLocal = offset
      return `top: ${offset}px`
    },
    permissionSave(){
      let keys = [
        'actionId',
        'eventId',
        'goTo',
        'screen',
        'condition'
      ]

      if(this.defaultTrans) 
        keys = keys.filter(action => action !== 'eventId')

      return keys.every(action => this.actionForElementData[action])
    }
  },
  methods: {
    editRuleElement() {
      this.elementId = this.rule.elementId
      this.actionForElementData = JSON.parse(JSON.stringify(this.rule))
      
      let connectionData
      if(this.isScreenEdit) connectionData = this.availableScreens.find(node => node.screenId === this.currentScreenId)
      else connectionData = this.availableScreens.find(node => node.screenId === this.rule.nodeToId)
    
      this.$set(this.actionForElementData, 'goTo', connectionData.nodeId) //node
      this.$set(this.actionForElementData, 'screen', connectionData.screenId) // screen
    },

    updateExpression(expression){
      this.$set(this.actionForElementData, 'condition', {'expression': expression})
    },

    save(){
      const newCondition = {
        actionId: this.actionForElementData.actionId,
        eventId: this.actionForElementData.eventId,
        order: this.actionForElementData.order,
        condition: this.actionForElementData.condition,
        nodeFromId: this.actionForElementData.nodeFromId ?? null,
        nodeToId: this.actionForElementData.screen ?? null,
        elementId: this.actionForElementData.elementId ?? null,
        id: this.actionForElementData.id ?? null
      }

      this.$emit('save', newCondition)
      this.$store.dispatch('node/heightOfPopup', 0)
    },

    cancel(){
      this.$emit('cancel')
      this.$store.dispatch('node/heightOfPopup', 0)
      this.actionForElementData = null
    },
    // addAction(){
    //   console.log("addAction in popup:")
    //   this.isAddAction = !this.isAddAction
    // },
    // addEvent(){
    //   console.log("addEvent in popup:")
    //   this.isAddEvent = !this.isAddEvent
    // },

    save2(){

    },
    save3(){

    },
    setHightBg(){
      const bottomOffset = this.isScreenEdit ? 300 : 500
      const typeOffset = this.isScreenEdit ? 'heightScreenEditComponent' : 'heightOfPopup'
      const clientHeight = document.getElementById('cardPopupRule').clientHeight

      this.$store.dispatch(`node/${typeOffset}`,  this.position + this.positionPopup + clientHeight + bottomOffset )
    }
  },
  created(){
    if(this.edit) this.editRuleElement()
  },
  mounted(){
   this.setHightBg()
  }
}

</script>

<style lang="scss" scoped>

.popup{
  width: 100% !important; 
  z-index: 20;
  position: absolute;
  left: 10px;
  width: 95% !important;
}
.bg_popup{
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(#212121, 0.4);
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: start;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>