<template>
  <v-col cols="11" style="margin-bottom: -12px;">
    <div class="if-operation__options">
      <div class="d-flex justify-space-between conditions-settings__hide">
        <v-autocomplete
          class="select_conditions"
          :items="savedConditionsCopy"
          @input="onConditionChange"
          v-model="newConditionId"
          item-value="id"
          item-text="ruleName"
          color="deep-purple accent-2"
          :label="$t('node.intoCondition.selectCondition')"
          style="width:calc(100% - 40px);"
          return-object
          hide-details
        />
        <span class="text-caption"> {{ newConditionId }}</span>
         <div style="width:40px;">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">               
              <v-btn x-small text @click="visibleFields" :color="colorGlasses" v-bind="attrs" v-on="on">
                <v-icon>mdi-glasses</v-icon>
              </v-btn>
            </template>
            <span>{{$t('node.intoCondition.showRule')}}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">               
              <v-btn x-small text @click="deleteConditionPopup" color="error" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('node.intoCondition.delRule')}}</span>
          </v-tooltip>
         </div>
      </div>

      <div v-if="isShowConditionInto" class="conditions-settings mt-2">
        <div v-for="condition in conditions" :key="condition.type">
          <label class="conditions-settings__label">{{$t(`node.intoCondition.${condition.type}`)}}</label>
          <v-select
            v-if="condition.type !== 'answer'"
            :items="condition.value"
            v-model="condition.value"
            outlined
            dense
            readonly
          />
          <div v-else class="d-flex">
            <v-select
              v-for="answer in condition.value"
              :key="answer.type"
              :items="answer.value"
              v-model="answer.value"
              :style="`width:${answer.width}%`"
              outlined
              dense
              readonly
            />
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn elevation="0" class="white--text mb-1" small color="deep-purple accent-2" @click="visibleFields">
            {{ $t('close') }}
          </v-btn>
        </div>
      </div>
    </div>

    <div class="d-flex justify-center mt-2" v-if="last">
      <v-btn color="grey" class="mr-2" :disabled="addRule" outlined small fab @click="addCondition('or')">OR</v-btn>
      <v-btn color="grey" :disabled="addRule" outlined small fab @click="addCondition('and')">AND</v-btn>
    </div>

  </v-col>
</template>

<script>
import searchConditions from "@/mixins/searchConditions";
export default {
  name: 'RuleSettings',
  props: {
    ruleId: {
      type: Number
    },
    last: {
      type: Boolean
    }
  },
  mixins: [searchConditions],
  data(){
    return{
        newConditionId: this.ruleId,
        isShowConditionInto: false,
    }
  },
  methods: {
    addCondition(operator){
      this.$emit('addCondition', operator)
    },
    deleteConditionPopup() {
      this.$emit('deleteCondition')
    },
    visibleFields(){
      this.isShowConditionInto = !this.isShowConditionInto
    }
  },
  computed: {
    colorGlasses(){
      return this.isShowConditionInto
        ? '#A37CF0'
        : '#e2e2e2'

    },

    addRule(){
      return this.newConditionId === 0
    },

    conditions(){
      const condition = this.savedConditionsCopy.find(condition => condition.id === this.newConditionId)
      const stub = 'undefined'
      return [
        {type: 'condition', value: condition.conditionType.length ? condition.conditionType : stub},  
        {type: 'service', value: condition.service.value ?? stub}, 
        {type: 'action', value: condition.method.value ?? stub}, 
        {type: 'answer', value: [
          {type: 'answerName', width: 40, value: condition.answerId ?? stub},
          {type: 'operator', width: 20, value: condition.operator ?? stub},
          {type: 'resultApi', width: 40, value: condition.resultApiField ?? stub}
        ]}
      ]
    },
  },
  watch: {
    newConditionId(id){
        if(id) {
          console.log('newConditionId - id', id)
          this.$emit('changeCondition', id)
        }
    }
  }
}
</script>

<style scoped lang="scss">
.conditions-settings{
   border:1px solid #A37CF0;
   padding: 20px 10px 10px;
   border-radius: 8px;
   margin-bottom: 25px;

   &__hide{
      border:1px solid #e2e8f0;
      padding:4px 10px;
      border-radius: 10px;
   }

   &__label {
      display: block;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 8px;
   }
}

.v-text-field{
  margin: 0;
}
</style>
