import {processesService} from "@/services/processes.service";
import Vue from "vue";
import {DEFAULT_CANVAS_THEME} from "@/constants/canvas";

export const processes = {
  namespaced: true,
  state: {
    processes: [],
    process: {},
    showNav: false,
    isEditNav: false,
    reloadProcesses: false,
    newProcess: null,
    outsideProcessId: null,
    editorData: {
      nodes: {},
      connections: {},
      options: {
        /**
         * Размер сетки
         */
        cellSize: 50,
        /**
         * Включает/отключает привязки
         */
        gridBinding: true,
        canvasTheme: DEFAULT_CANVAS_THEME,
      },
      availableNodes: {},
      enterNode: {},
    },
    localContext: null,
    groupedContext: null,
    outGroupContext: null,
    parentNodeId: null,
    actionsProcess: [],
    copyOfActionsProcess: [],
  },
  getters: {
    processes: state => state.processes,
    process: state => state.process,
    idOfProcess: state => state.process?.id,
    nameOfProcess: state => state.process?.process_name,
    processN8N: state => state.process?.process_n8n,
    showNav: state => state.showNav,
    outsideProcessId: state => state.outsideProcessId,
    newProcess: state => state.newProcess,
    isEditNav: state => state.isEditNav,
    reloadProcesses: state => state.reloadProcesses,
    editorData: state => state.editorData,
    localContext: state => state.localContext,
    groupedContext: state => state.groupedContext,
    outGroupContext: state => state.outGroupContext,
    parentNodeId: state => state.parentNodeId,
    canvasTheme: state => state.editorData.options.canvasTheme,
    enterNode: state => state.editorData.enterNode,
    actionsProcess: state => state.actionsProcess,
    getContext: state => state.localContext ?? state.editorData,
  },
  mutations: {
    getProcesses (state, processes) {
      state.processes = processes;
    },
    getProcess (state, process) {
      state.process = process;
    },
    changeShowNav (state, value) {
      state.showNav = value
    },
    setOutsideProcessId (state, value) {
      state.outsideProcessId = value;
    },
    setNewProcess (state, value) {
      state.newProcess = value;
    },
    changeIsEditNav (state, value) {
      state.isEditNav = value
    },
    changeReloadProcesses (state, value) {
      state.reloadProcesses = value
    },
    setEditorData(state, editor_data) {
      state.editorData = editor_data;
    },
    changeOutputName(state, {outputName, index, node}){
      Vue.set(node, `${index}`, outputName)
      processesService.saveProcess(state.editorData, state.process.id)
    },
    setLocalContext(state, data){
      Vue.set(state, 'localContext', data)
    },

    setCanvasTheme(state, value) {
      state.editorData.options.canvasTheme = value;
    },
    setNameOfProcess(state, name){
      state.process.process_name = name
    },
    setGroupedContext(state, data){
      state.groupedContext = data
    },
    setOutGroupContext(state, data){
      state.outGroupContext = data
    },
    setParentNodeId(state, data){
      state.parentNodeId = data
    },
    setEnterNode(state, data){
      state.editorData.enterNode = data
    },

    setActionsProcess(state, data) {
      if(!data.length) return state.actionsProcess = []

      state.actionsProcess = data.map(action => {
        if(!action?.condition?.expression) return action

        const expression = action.condition.expression
        if(typeof expression !== 'string') return action
  
        const expressionToArr = expression.split(' ')
          .map(item => {
            if(item === '&') return {operator: 'and'}
            if(item === '|') return {operator: 'or'}
            return {rule: +item.split('rule.')[1]}
          })
          .filter(expression => !!Object.keys(expression).length)
  
        action.condition.expression = expressionToArr
        return action
      })
    },

    deleteActionsProcess(state, actionProcessId) {
      if(state.copyOfActionsProcess.length) state.copyOfActionsProcess = state.actionsProcess
        .filter(actionProcess => actionProcess.id !== actionProcessId)

      else state.actionsProcess = state.actionsProcess
        .filter(actionProcess => actionProcess.id !== actionProcessId)
    },

    addActionsProcess(state, actions){
      // let data = state.copyOfActionsProcess.length
      //   ? state.copyOfActionsProcess
      //   : state.actionsProcess

      if(actions?.eventId){
        return state.actionsProcess.find(action => {
           if(action.nodeFromId === actions.nodeFromId && action.nodeToId === actions.nodeToId && action.eventId === actions.eventId)
          return action.condition = actions.condition
        })
      }
      return state.actionsProcess.find(action => {
        if(!action.eventId && action.nodeFromId === actions.nodeFromId && action.nodeToId === actions.nodeToId) 
        return action.condition = actions.condition
      })

    },

    copyActionsProcess(state, copyAction){
      if(!copyAction) return state.copyOfActionsProcess = []
      state.copyOfActionsProcess = JSON.parse(JSON.stringify(state.actionsProcess))
    }
  },
  actions: {
    changeShowNav ({ commit }, value) {
      commit('changeShowNav', value)
    },
    setNewProcess ({commit}, value) {
      commit('setNewProcess', value);
    },
    changeIsEditNav ({ commit }, value) {
      commit('changeIsEditNav', value)
    },
    setOutsideProcessId({commit}, id) {
      commit("setOutsideProcessId", id);
    },
    changeReloadProcesses ({ commit }, value) {
      commit('changeReloadProcesses', value)
    },
    async getProcesses ({ commit }) {
      await processesService.getProcesses()
        .then(processes => {
          commit('getProcesses', processes.response.processes)
        })
        .catch(() => {
          commit('getProcesses', [])
        })
    },
    async getProcess ({ commit }, id) {
      if (id) {
        const response = await processesService.getProcess(id);
        if (response?.status === 1) {
          commit('getProcess', response.response);
        } else {
          commit('getProcess', null);
        }
      }
    },
    async deleteProcess ({ commit }, id) {
      await processesService.deleteProcess(id);
    },
    async saveProcess({state, getters, dispatch}, project) {
      await processesService.saveProcess(project ? project : state.editorData, getters.process.id)

      // load actionsProcess after save process
      const processId = localStorage.getItem('processId')
      dispatch('getActionsProcess', {processId})
    },
    async updateNameProcess({commit, state, getters}, name) {
      commit('setNameOfProcess', name)
      await processesService.updateNameProcess(name, state.editorData, getters.process.id)
    },
    async addProcess ({ commit }, process_settings) {
      return await processesService.addProcess(process_settings);
    },
    setEditorData({commit}, editor_data) {
      commit('setEditorData', editor_data)
    },
    changeOutputName({commit, dispatch, state}, {nodeId, outputName, index}) {
      let context = state.localContext ?? state.editorData
      let node = context.nodes[nodeId]?.overwrittenNodeType?.outputNames

      if(node !== undefined) return commit('changeOutputName', {outputName, index, node})    
      dispatch('alert/error', 'Невозможно задать имя выхода', { root: true })
    },
    setLocalContext({commit}, data){
      commit('setLocalContext', data)
    },
    setCanvasTheme({ commit }, data) {
      commit('setCanvasTheme', data)
    },
    setGroupedContext({ commit }, data) {
      commit('setGroupedContext', data)
    },
    setOutGroupContext({ commit }, data) {
      commit('setOutGroupContext', data)
    },
    setParentNodeId({ commit }, data) {
      commit('setParentNodeId', data)
    },
    setEnterNode({commit}, data){
      commit('setEnterNode', data)
    },

    async getActionsProcess ({ commit }, {processId, elementId, nodeFromId, nodeToId}) {
      await processesService.getActionsProcess({processId, elementId, nodeFromId, nodeToId})
        .then(actionsProcess => {
          console.log('actionsProcess.response', actionsProcess.response.actions)
          commit('setActionsProcess', actionsProcess.response.actions)
        })
        .catch(() => {
          commit('setActionsProcess', [])
        })
    },

    async saveActionsProcess ({ commit, getters, dispatch }, actionProcess) {
      if(!actionProcess?.condition?.expression) return console.error('expression is not defined')

      const stringExpression = await dispatch('expressionToString', actionProcess.condition.expression)
      const filteredObj = await dispatch('filterEmptyValues', actionProcess)

      await processesService.saveActionsProcess({...filteredObj, processId: getters.process.id, condition: {expression: stringExpression}})
        .then(res => {
          if(res?.status !== 1) return console.error(res.message)
          console.log('saveActionsProcess', res.response)
          commit('addActionsProcess', {...filteredObj, processId: getters.process.id, id: res.response.id})
        })
        .catch(e => console.error(e.message))
    },

    filterEmptyValues({commit}, obj){
      const filteredObj = {}
      for (const key in obj) {
        if (obj[key]) filteredObj[key] = obj[key]
      }
      return filteredObj
    },

    async editActionsProcess ({ commit, getters, dispatch }, actionProcess) {
      commit('copyActionsProcess', true)

      await dispatch('deleteActionsProcess', {processId: getters.process.id, actionProcessId: actionProcess.id})
      delete actionProcess.id
      await dispatch('saveActionsProcess', actionProcess)

      commit('copyActionsProcess', false)
    },

    async deleteActionsProcess ({ commit }, {processId, actionProcessId}) {
      await processesService.deleteActionsProcess({processId, actionProcessId})
      .then(res => {
        if(res?.status !== 1) return console.error(res.message)
        console.log('deleteActionsProcess', res.message)
        commit('deleteActionsProcess', actionProcessId)
        return true
      })
      .catch(e => console.error(e.message))
    },

    expressionToString({commit}, expression){
      return expression.reduce((string, item) => {
          if(item?.rule) return string += 'rule.' + item.rule
          if(item.operator === 'and') return string += ' & '
          return string += ' | '
        }, '')
    },

    async deleteConnection({commit, getters, dispatch}, {processId, dataConnection}) {
      await processesService.deleteConnection(processId, dataConnection)
      .then(res => {
        if(res?.status !== 1) return console.error(res.message)
        dispatch('getActionsProcess', {processId: getters.process.id}) 
      })
      .catch(e => console.error(e.message))
    }
  }
};
