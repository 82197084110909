<template>
  <SpinnerLoader
    v-if="loading"
  />
  <div v-else class="business-process">
    <div class="d-flex align-center mb-4">
      <v-btn
        icon
        dark
        large
        class="mr-1"
        @click="openNav(false)"
      >
        <v-icon>
          mdi-plus-box-multiple
        </v-icon>
      </v-btn>
      <v-btn
          icon
          dark
          large
          class="mr-1"
          @click="toggleSearch"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <div v-show="menuOptions.showSearch" style="width: 150px">
        <v-text-field
            style="width: 150px"
            outlined
            dark
            dense
            hide-details
            autofocus
            label="Seаrching process"
            v-model="menuOptions.searchProcess"
        />
      </div>
      <v-tabs
        v-model="selectedProcessTab"
        :style="{'padding-right': menuOptions.menuPaddingRight}"
        show-arrows
        background-color="transparent"
        dark
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="process in filteredProcessItems"
          :key="process.id"
          :href="'#tab-' + process.id"
          @click="loadProcess(process.id)"
        >
          {{ process.process_name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-card
      class="pa-6 rounded-xl"
    >
    <div class="mb-6">
      <div class="d-flex mb-1 align-center">
        <h2 class="text-h5">{{ $t('process.process') }}: {{ nameOfProcess }}</h2>
        <transition
          v-if="selectedProcess && !isErrors"
          name="save-btn"
          mode="out-in"
        >
          <img
            v-if="graphUpdated" key="1"
            src="../assets/icons/success.svg" alt="save"
            class="save-btn ml-2"
          >
          <img
            v-else key="2"
            @click="saveProcess"
            src="../assets/icons/save.svg" alt="save_arrow"
            class="save-btn ml-2"
          >
        </transition>
        <div v-if="isErrors" class="errors">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="#f5f5f5" elevation="0" v-bind="attrs" v-on="on">
                Errors
              </v-btn>
            </template>
            <p v-if="!permissionToSave.forbid">{{ $t('process.errorStartNode') }}</p>
            <ul v-if="unknownNodes.isBrokenNodes">
              <span>{{ $t('process.errorCustomNode') }}</span>
              <li
                v-for="nameNode,i in unknownNodes.brokenNodes"
                :key="i"
              >
                {{ nameNode }}
              </li>
            </ul>
          </v-tooltip>
        </div>
        <div v-if="isWarning" class="warnings">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="#f5f5f5" elevation="0" v-bind="attrs" v-on="on">
                Warning
              </v-btn>
            </template>
            <ul>
              <span>{{ $t('process.warnNoRules') }}</span>
              <li
                v-for="item,i in unselectedConditions"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="openNav(true)"
        >
          <v-icon>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </div>
    </div>

      <SpinnerLoader
        v-if="processLoading"
        color="deep-purple accent-2"
      />
      <div v-else-if="editorData">
        <NodeEdit
          v-if="dialog"
          :node="node"
          :dialog="dialog"
          :localContext="getContext"
          :outGroupContext="outGroupContext"
          :parentNodeId="parentNodeId"
          :enterNode="editorData.enterNode.db_id === node.db_id"
          @close="dialog = false"
          />
          <div class="business-process__graph" v-if="selectedProcess">
            <graph-editor
            style="height: calc(100vh - 272px)" 
            v-bind.sync="editorData"
            @deleteNode="deleteNode($event), deleteConnectNode($event)"
            @nodeDblClick="selectNode"
            @addNode="addNode"
            :options.sync="canvasOptions"
            @connectNode="changeConnects('addConnection', $event)"
            @deleteConnection="changeConnects('deleteConnection', $event)"
            @deleteSelectedNodes="deleteSelectedNodes"
            @changeSubgroupContexts="changeSubgroupContexts"
            @group="group"
            @ungroup="unGroup"
            @replacementNode="replacementNode"
            @stepBefore="stepBefore"
            />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'

import { EventBus } from "@/utils/event-bus";
import NodeEdit from "@/components/nodes/NodeEdit";
import {DEFAULT_CANVAS_THEME} from "@/constants/canvas";


export default {
  name: 'BusinessProcess',
  components: {
    NodeEdit
  },
  data () {
    return {
      loading: false,
      selectedProcess: null,
      node: null,
      currentState: null,
      trans: false,
      localContext: null,
      dialog: false,
      processLoading: false,
      isProcessAdd: false,
      canSave: false,
      canvasOptions: {
        loading: false
      },
      editorData: {
        nodes: {},
        connections: {},
        options: {
          /**
           * Размер сетки
           */
          cellSize: 50,
          /**
           * Включает/отключает привязки
           */
          gridBinding: true,
          canvasTheme: DEFAULT_CANVAS_THEME,
        },
        availableNodes: {},
        enterNode: {}
      },
      menuOptions: {
        searchProcess: '',
        showSearch: false,
        menuPaddingRight: '80px',
      },
      idOutNode: null,
      outGroupContext: null,
      parentNodeId: null,
      isSaveConnects: false,
      isSaveNode: false,
      loadEnterNode: false,
      workWithGroup: false,
      graphUpdated: false,
      tempPurpleNodes: [759956, 760990, 761012, 761034, 761056, 761213, 761257, 761235, 761679, 761953, 761960, 761967, 761974, 761988, 761991],
      historyAction: []
    }
  },
  watch: {
    'editorData.connections': {
      handler: function () {
        this.canSave = true;
        this.isSaveConnects = true

        // track connection for Enter node
        if(this.permissionToSave.forbid && this.enterNode) {
          const enterNodeId = Object.values(this.editorData.nodes)
            .filter(node => node.type !== 'group')
            .find(node => node.db_id === this.enterNode.db_id).id
          return this.$set(this.editorData.nodes[enterNodeId], 'withOutInput', false)
        }
        this.permissionToSave.enterNodes.forEach(nodeId => this.$set(this.editorData.nodes[nodeId], 'withOutInput', true))
      },
      deep: true
    },
    'editorData.nodes': {
      handler: function (newNodes, oldNodes) {
        const lengthNewNodes = Object.keys(newNodes).length
        const lengthOldNodes = Object.keys(oldNodes).length
        const enterNode = this.editorData.enterNode

        if(lengthNewNodes > lengthOldNodes && lengthOldNodes !== 0){
          this.isSaveNode = true
        }

        // for track new Enter node
        if(enterNode && enterNode?.db_id !== this.enterNode?.db_id && this.enterNode?.db_id
            && !this.loadEnterNode && lengthNewNodes > 0 && lengthOldNodes > 0){
          this.setEnterNode(this.enterNode.db_id)
        }
      },
      deep: true
    },
    'editorData.options.canvasTheme': {
      handler: async function () {
        this.canSave = true;
      },
      deep: true
    },
    reloadProcesses: {
      handler: async function (value) {
        if (value) {
          await this.getProcesses()
          this.$store.dispatch('processes/changeReloadProcesses', false)
        }
      },
      immediate: true
    },
    async outsideProcessId(id) {
      if (id) {
        await this.loadProcess(id);
        this.$store.dispatch('processes/setOutsideProcessId', null);
      }
    },
    dialog: {
      handler: function (value) {
        if (value) {
          document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
        } else {
          document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
        }
      },
      immediate: true
    },
    'getContext.connections': {
      handler: function (newValue, oldValue) {
        if (Object.keys(oldValue).length === 0 || Object.keys(oldValue).length < Object.keys(newValue).length || this.trans) this.currentState = newValue
        else this.currentState = oldValue
        this.trans = false
      },
      deep: true
    },
    graphUpdated: {
      handler: function (value) {
        if(value) setTimeout( _ => this.graphUpdated = false, 1500)
      }
    },
    isErrors: {
      handler: function (value) {
        // if !value, on each item of historyAction sending request for backend
        if(!value) {
          console.log('isErrors', value)
          this.changedPrecess()
        }
      }
    },

    historyAction: {
      handler: function (value) {
        if(!this.isErrors) this.changedPrecess()
      }
    }
  },
  computed: {
    reloadProcesses () {
      return this.$store.getters['processes/reloadProcesses']
    },
    showNav () {
      return this.$store.getters['processes/showNav']
    },
    processItems () {
      return this.$store.getters["processes/processes"];
    },
    filteredProcessItems () {
      return this.processItems.filter(proc => proc.process_name.toLowerCase().includes(this.menuOptions.searchProcess.toLowerCase()));
    },
    process () {
      return this.$store.getters['processes/process'];
    },
    outsideProcessId() {
      return this.$store.getters["processes/outsideProcessId"];
    },
    nameOfProcess () {
      return this.$store.getters["processes/nameOfProcess"];
    },
    selectedProcessTab: {
      get() {
        return 'tab-' + this.selectedProcess
      },
      set(new_val) {

      }
    },
    getContext:{
      cache: false,
      get(){
        return this.localContext ?? this.editorData
      }
    },

    // check node without income connect
    permissionToSave:{
      cache: false,
      get(){
        const nodes = Object.keys(this.getContext?.nodes)
        const inConnections = Object.values(this.getContext.connections).reduce((inConnectNodes, node) => {
          Object.values(node).map(item => inConnectNodes.push(item[0].endNodeId))
          return inConnectNodes
        }, [])

        // check to input and output of node
        nodes.filter(node => !inConnections.includes(node))
          .map(node => {
            if(!Object.keys(this.getContext.connections).includes(node)) this.$set(this.getContext.nodes[node], 'withOutInput', true)
          })

        nodes.filter(node => inConnections.includes(node))
          .map(node => {
            this.$set(this.getContext.nodes[node], 'withOutInput', false)
          })

        const enterNodes = nodes.filter(node => !inConnections.includes(node))

        const oneNodeOnCanvas = enterNodes?.length === 1
        // eslint-disable-next-line
        if(oneNodeOnCanvas && Object.keys(this.editorData.nodes)[0] === nodes[0]) this.$set(this.editorData.nodes[enterNodes[0]], 'withOutInput', false)

        const forbid = !this.workWithGroup
          ? oneNodeOnCanvas || nodes.length <= 1
          : true

        return {forbid, enterNodes}
      }
    },

    // nodes whose parent id don't coincidence to available nodes
    unknownNodes(){
      const availableNodesId = Object.values(this.editorData.availableNodes)
        .map(node => node.dbId)

      const nodesParentId = Object.values(this.getContext.nodes)
        .map(node => node?.parent_id)
        .filter(node => node)

      const differentNodes = nodesParentId.filter(node => {
        return !availableNodesId.includes(node)
      })

      Object.keys(this.getContext.nodes).map(node => {
        this.$set(this.getContext.nodes[node], 'unknownNodes', false)
      })

      const brokenNodes = Object.values(this.getContext.nodes)
        .filter(item => differentNodes.includes(item.parent_id))
        .map(item => {
          this.$set(this.getContext.nodes[item.id], 'unknownNodes', true)
          return item.displayName
        })
      return { isBrokenNodes: differentNodes.length > 0, brokenNodes}
    },

    // nodes with out conditions
    unselectedConditions: {
      cache: false,
      get(){
        const unselectedConditions = []
        const nodes = this.getContext.nodes
        const connections = this.getContext.connections

        for(let id in connections){
          let toPush = Object.values(connections[id])
            .map(item => {
                if(!item[0].connections || item[0]?.connections.length === 0) return item[0].endNodeId
                else this.$set(this.getContext.nodes[id], 'unselectedConditions', false)
            })
            .filter(item => item)
            if(toPush.length > 0) {
              toPush.map(item => {
                if(nodes[item]?.displayName && nodes[id]?.displayName) unselectedConditions.push(
                  `${nodes[id].displayName} -> ${nodes[item].displayName}`
                )
                if(!this.workWithGroup) this.$set(this.getContext.nodes[id], 'unselectedConditions', true)
              })
            }
        }
        return unselectedConditions
      }
    },

    isErrors:{
      cache: false,
      get(){
        return !this.permissionToSave.forbid || this.unknownNodes.isBrokenNodes
      }
    },

    isWarning:{
      cache: false,
      get(){
        return this.unselectedConditions.length > 0
      }
    },

    enterNode(){
      if(this.permissionToSave.forbid) return this.editorData.nodes[this.permissionToSave.enterNodes[0]]
      return this.editorData?.enterNode
    }
  },
  async created () {
    // get processes list
    await this.getProcesses();
    // get conditions and conditions options
    await this.getInitConditions();

    if(this.enterNode) 
      EventBus.$on('updatePresetIdOfEnterNode', (presetId) => {
        if(this.editorData?.enterNode) presetId = this.editorData.enterNode.presetId
      this.$store.dispatch('processes/setEnterNode', this.enterNode)
    })

    Object.keys(this.editorData.nodes).map(node => {
      if (this.tempPurpleNodes.includes(this.editorData.nodes[node].db_id)) {
        console.log("Красим");
        this.$set(this.editorData.nodes[node], 'nodeColor', '#8c1abb');
      }
    })
    // get actions
    this.$store.dispatch("actions/setActions")
    // get events
    this.$store.dispatch("events/setEvents")
    // get actionProcess 
    this.$store.dispatch('processes/getActionsProcess', {processId: this.selectedProcess});
  },
  methods: {
    /**
     * function for replace node to new node
     * @param {object} data - object with replacementNode, newNode, parentNodes
     * 
     * argument of the replacementNode function
     * @typedef {Object} data
     * @property {object} replacementNode - node to be replaced
     * @property {object} newNode - new node
     * @property {array} parentNodes - parent nodes who has connection with node
     */
    
    async replacementNode(data){
      const isGroup = !!data.event
      const replacementData = data.event 
        ? data.event
        : data
      
      let copyNodes = JSON.parse(JSON.stringify(this.editorData.nodes))
      let copyConnections = JSON.parse(JSON.stringify(this.editorData.connections))
      
      if(isGroup){
          let intoGroup = copyNodes
          let pathGroup = this.editorData.nodes

          data.subgroupContextPath.forEach((item,i) => {
            if(i === data.subgroupContextPath.length - 1 ) {
              pathGroup = pathGroup[item].grouped
              return intoGroup = intoGroup[item].grouped
            }

            intoGroup = intoGroup[item].grouped.nodes
            pathGroup = pathGroup[item].grouped.nodes
          })

          // replace connections
          intoGroup.connections = JSON.parse(
            JSON.stringify(intoGroup.connections)
              .split(replacementData.replacementNode.id).join(replacementData.newNode.id)
          )

          // delete node
          await this.deleteNode(replacementData.replacementNode, false)
          
          delete intoGroup.nodes[replacementData.replacementNode.id]
          this.$set(pathGroup, 'nodes', intoGroup.nodes)
          this.$set(pathGroup, 'connections', intoGroup.connections)

          return
      }

      // replace connections
      copyConnections = JSON.parse(
        JSON.stringify(copyConnections)
          .split(replacementData.replacementNode.id).join(replacementData.newNode.id)
      )

      // delete node
      await this.deleteNode(replacementData.replacementNode, false)

      delete copyNodes[replacementData.replacementNode.id]
      this.$set(this.editorData, 'nodes', copyNodes)
      this.$set(this.editorData, 'connections', copyConnections)
    },

    async getInitConditions() {
      this.loading = true;
      // get conditions
      await this.$store.dispatch('node/getConditions', {offset: 0})
      this.loading = false;
    },
    openNav (isEdit) {
      this.$store.dispatch('processes/changeIsEditNav', isEdit);
      if (this.showNav) {
        this.$store.dispatch('processes/changeShowNav', false);
      }
      this.$store.dispatch('processes/changeShowNav', true);
    },
    toggleSearch() {
      this.$set(this.menuOptions, 'showSearch', !this.menuOptions.showSearch);
      this.$set(this.menuOptions, 'menuPaddingRight', this.menuOptions.showSearch ? '235px' : '80px');
    },
    // post node to server, to get node info (duplicate nodes)
    async addNode (node) {
      const workNode = node?.event ? node.event : node;
      const inGroup = !!node?.event;

      if (workNode.type !== 'group') {
        Vue.set(this.canvasOptions, "loading", true);
        // get node id
        const local_id = workNode.id;
        // get dbId of node
        const node_id = this.$store.getters["node/availableNodes"][workNode.type].dbId;
        // get process id
        const process_id = this.$store.getters["processes/process"].id;
        // get info about node from server
        const nodeInfo = await this.$store.dispatch('node/getNodeId', {node_id, process_id});
        // get info about node from workflow data
        let createdNode = this.editorData.nodes[local_id];

        if (inGroup) {
          let resultPath = this.editorData.nodes[node.subgroupContextPath[0]];
          node.subgroupContextPath.forEach((id, index) => {
            if (index > 0) {
              resultPath = resultPath.grouped.nodes[id];
            }
          })

          // rewrite info about node if it in group (workflow data)
          createdNode = resultPath.grouped.nodes[local_id];
          resultPath.grouped.nodes[local_id] = {...createdNode, ...nodeInfo};
        } else {
          this.editorData.nodes[local_id] = {...createdNode, ...nodeInfo};
        }

        await this.saveProcess();
        this.isSaveNode = false

        Vue.set(this.canvasOptions, "loading", false);
      }
    },
    async getProcesses () {
      this.loading = true;
      await this.$store.dispatch("processes/getProcesses");
      if (this.$store.getters["processes/newProcess"]) {
        this.processItems.forEach(proc => {
          if (proc.process_name === this.$store.getters["processes/newProcess"]) {
            localStorage.setItem('processId', proc.id);
            this.$store.dispatch('processes/setNewProcess', null);
          }
        })
      }
      if (localStorage.getItem('processId') && this.processItems) {
        this.selectedProcess = +localStorage.getItem('processId');
        if (!this.processItems.some(proc => proc.id === this.selectedProcess)) {
          this.selectedProcess = this.processItems[0].id;
        }
        await this.loadProcess(this.selectedProcess);
      }
      this.loading = false;
    },
    async loadProcess (id) {
      this.processLoading = true;
      await this.$store.dispatch("processes/getProcess", id);
      // get / set avilable nodes for graph
      if (this.process) {
        this.editorData = JSON.parse(JSON.stringify(this.$store.getters["processes/processN8N"]));

        if (!this.editorData) {
          this.$store.dispatch('alert/error', new Error('Нет бизнеc процесса'));
          this.$store.dispatch('node/setNullSettings', null);
          await this.$store.dispatch("processes/getProcess", null);
          EventBus.$emit('nodeGlobalReset');
          localStorage.removeItem('processId');
        } else {
          await this.$store.dispatch('node/getAvailableNodes', this.editorData?.options);
          this.editorData.availableNodes = this.$store.getters["node/availableNodes"];
          this.$store.dispatch('processes/setEditorData', this.editorData)
          localStorage.setItem('processId', id);
          this.selectedProcess = id;
        }

        Object.keys(this.editorData.nodes).map(node => {
          if (this.tempPurpleNodes.includes(this.editorData.nodes[node].db_id)) {
            this.$set(this.editorData.nodes[node], 'nodeColor', '#8c1abb');
          }
        })
      }
      this.processLoading = false;
      this.$forceUpdate();
    },

    // save process workflow
    async saveProcess (showLoad = true) {

      // save process if have once enter node 
      if(!this.isErrors){
        if(showLoad) Vue.set(this.canvasOptions, "loading", true);
          await this.$store.dispatch('processes/saveProcess', this.editorData);
          this.canSave = false;
        if(showLoad) Vue.set(this.canvasOptions, "loading", false);
        this.graphUpdated = true;
      }
    },
    // delete node by node id
    async deleteNode (node, save = true) {
      const workNode = node?.event ? node.event : node;

      if (workNode.type !== 'group') {
        // add to historyAction node for delete
        this.historyAction.push({deleteNode: workNode.db_id})
      } else {
        for (let key in workNode.grouped.nodes) {
          if (workNode.grouped.nodes[key]?.db_id) {
            // add to historyAction node into grouped for delete  
            this.historyAction.push({deleteNode: workNode.grouped.nodes[key].db_id})
          }
        }
      }

      if(save) setTimeout(async () => {
        await this.saveProcess(false)
      }, 0)

    },
    // Select node by dblclick node on canvas
    selectNode (node) {      
      const workNode = node?.event ? node.event : node;

      // Don't open node, if groupInput or groupOutput or group
      if(workNode.node.type === 'groupInput' || workNode.node.type === 'groupOutput' || workNode.node.type === 'group' || !this.permissionToSave.forbid) return
      
      this.node = workNode.node;
      let connectionsNodes = [];

      if( this.getContext.connections[workNode.node.id] !== undefined){
        Object.values(this.getContext.connections[workNode.node.id]).forEach(out => {

          // use for last node in group, under the exit node
          if(this.getContext.nodes[out[0].endNodeId].type === 'groupOutput'){
            return connectionsNodes.push(this.idOutNode)
          }
          if( this.getContext.nodes[out[0].endNodeId].type === 'group' ){
            const groupInput = Object.values(this.getContext.nodes[out[0].endNodeId].grouped.nodes).find(node => node.type === 'groupInput').id
            const inputNode = Object.values(this.getContext.nodes[out[0].endNodeId].grouped.connections[groupInput])[0][0].endNodeId
            const firstNode = this.getContext.nodes[out[0].endNodeId].grouped.nodes[inputNode].db_id

            return connectionsNodes.push(firstNode)
          }
          connectionsNodes.push(this.getContext.nodes[out[0].endNodeId].db_id)
        })
      }

      this.$store.dispatch('node/setCurrentNode', workNode.node);
      this.$store.dispatch('node/setOuterConnections', connectionsNodes);
      this.$store.dispatch('pageBuilder/getNode', workNode.node.db_id)
      this.dialog = true;
    },

    // add/remove connects
    async changeConnects(name, $event, deleteConnect = true, addLastStep = true){
      let currentEvent = $event?.event ? $event.event : $event
      let node = this.getContext.nodes[currentEvent.startNodeId]

      // remove connects
      if(name === 'deleteConnection' && node?.overwrittenNodeType) {

        // add to historyAction connection for delete
        const nodeFrom = $event.startNodeId
        const nodeTo = $event.endNodeId

        if(addLastStep) this.historyAction.push({deleteConnection: {nodeFrom: this.editorData.nodes[nodeFrom].db_id, nodeTo: this.editorData.nodes[nodeTo].db_id}})
        else {
          this.historyAction.find(action => {
            if(action?.deleteNode && action.deleteNode === this.editorData.nodes[nodeTo].db_id) action.nodeFrom = this.editorData.nodes[nodeFrom].db_id
          })
        }

        let indexName = node.overwrittenNodeType.outputs.indexOf(currentEvent.startNodeOutputName)
        node.overwrittenNodeType.outputNames.splice(indexName, 1)

        if(node.overwrittenNodeType.outputs.length > 1) node.overwrittenNodeType.outputs = node.overwrittenNodeType.outputs
          .filter(outputName => outputName !== currentEvent.startNodeOutputName)

        // if delete connect unmark unselectedConditions
        if(node.overwrittenNodeType.outputs.length === 1) node.unselectedConditions = false

        setTimeout(()=>{
          if(this.isSaveConnects && !this.isErrors && deleteConnect){
            return this.saveProcess(false)  
          }
          return 
        }, 0)
      }

      const connection = this.getContext.connections[currentEvent.startNodeId]

      // add connects
      if(name === 'addConnection' ){
        if(node.overwrittenNodeType !== undefined){

          if(node.overwrittenNodeType.outputs !== undefined){
            let outputs = node.overwrittenNodeType.outputs
            let outputNames = node.overwrittenNodeType.outputNames
            let nextName = "main" + (Number(outputs[outputs.length - 1].split('main')[1]) + 1)
              
            if(outputs.length === Object.keys(connection).length){
              outputs.push(nextName)
              outputNames.push('')
            }
          }

        }else{
          this.$set(node, 'overwrittenNodeType', {
            "outputs": ["main", "main1"],
            "outputNames" : [ '', '' ]
          })
        }

        // for correct save process after added connect
        setTimeout(() => {
          if(!this.isSaveNode && !this.isErrors && !this.loadEnterNode) this.saveProcess(false)
          else{
            this.waitForLoadEnterNode()
              .then(() => {
                this.saveProcess(false)
              })
          }
        }, 0)

        this.isSaveConnects = false
        this.isSaveNode = false
      }
    },

    waitForLoadEnterNode() {
      return new Promise(resolve => {
        const checkVariable = ()=>{
          if (!this.loadEnterNode) resolve()
          else setTimeout(checkVariable, 100)
        }

        checkVariable()
      })
    },

    // update context nesting
    changeSubgroupContexts(connects){
      if(connects.length === 0){
        this.localContext = this.outGroupContext = this.parentNodeId = this.idOutNode = null
        
      }else{
        this.outGroupContext = this.getContext

        let outNodeOutGroup = this.getContext.connections[connects[0].parentNodeId]
          ? Object.values(this.getContext.connections[connects[0].parentNodeId]).map(node => node[0].endNodeId)  // Ноды со связью с группой  
          : null

        if(outNodeOutGroup){
          this.idOutNode = outNodeOutGroup.reduce((arr, node) => {
            if( this.getContext.nodes[node].type === 'group') {
              const groupInputId = Object.values(this.getContext.nodes[node].grouped.nodes).find(node => node.type === 'groupInput').id
              const firstNodeInGroup = this.getContext.nodes[node].grouped.connections[groupInputId]['main'][0].endNodeId
              arr.push(this.getContext.nodes[node].grouped.nodes[firstNodeInGroup].db_id)
            }
              
            arr.push(this.getContext.nodes[node].db_id)
            return arr
          }, [])
        }
        else this.idOutNode = null

        this.idOutNode
          ? this.idOutNode.filter(item => item)
          : null
        
        this.parentNodeId = connects[0].parentNodeId
        this.localContext = connects[connects.length - 1].grouped
      }
      this.$store.dispatch("processes/setLocalContext", this.localContext)
      this.$store.dispatch("processes/setOutGroupContext", this.outGroupContext)
      this.$store.dispatch("processes/setParentNodeId", this.parentNodeId)
    },

    // remove bundle from the parent node
    async deleteConnectNode(node){

      let delId = node?.event?.id ? node.event.id : node.id
      let connects = Object.entries(this.currentState)
        .map(connect => {
          return Object.entries(connect[1]).reduce((arr, item) => {
            arr.push({ 
              "endNodeId": item[1][0]?.endNodeId,
              "startNodeOutputName": item[0], 
              "startNodeId": connect[0]
            })
            return arr
          }, [])
      })
      .map(connects => connects.filter(connect => connect.endNodeId == delId))
      .filter(arr => arr.length > 0)
      .map(item => item[0])
    
      if(connects.length > 0) return connects.forEach(connect => this.changeConnects('deleteConnection', connect, false, false))
    },

    // breaks nodes
    async deleteSelectedNodes(connects) {
      if(!this.isErrors){
        const nodes = connects?.event ? connects.event : connects

        Vue.set(this.canvasOptions, "loading", true)
          for (let key in nodes) {
            await this.deleteByPart(nodes[key])
          }
          await this.saveProcess()
        Vue.set(this.canvasOptions, "loading", false)
      }
    },

    async deleteByPart(node) {
        await this.deleteNode(node, false)
        this.deleteConnectNode(node)
    },
    
    // Don't delete again pls
    async handleChangeCanvasTheme(theme) {
      // Don't save changes :(
      this.$store.dispatch("processes/setCanvasTheme", theme);
      await this.loadProcess(this.selectedProcess);
    },

    // set connect to group and ungroup nodes
    setGroupConnect(parents, groupId, endNodeId, groupOutput, type){
      if(parents){
        parents.map(parent => 
          this.getContext.connections[parent.id] 
            ? this.$set(this.getContext.connections[parent.id], [parent.name] , [{endNodeId: groupId}])
            : this.$set(this.getContext.connections, [parent.id], {[parent.name]: [{endNodeId: groupId}]})
        )
      }
      
      if(endNodeId){

            const connects = endNodeId.reduce((arr, endNode, index) => {
              if(index === 0 ) arr['main'] = [{endNodeId: endNode}]
              else arr['main' + index] = [{endNodeId: endNode}]
              
              return arr
            }, {})
            
            
            const outputNames = endNodeId.reduce((arr, endNode, index) => {
              if(index === 0 ) {
                arr.outputNames.push('')
                arr.outputs.push('main')
              }else{
                arr.outputNames.push('')
                arr.outputs.push('main' + index)
              }

              if(endNodeId.length === ++index){
                arr.outputNames.push('')
                arr.outputs.push('main' + endNodeId.length)
              }

              return arr
            }, {outputNames: [], outputs: []})
          

          if(type === 'group'){
            this.$set(this.getContext.connections, [groupId], connects)
            this.$set(this.getContext.nodes[groupId], 'overwrittenNodeType', outputNames)
            
          }else{
            this.$set(this.getContext.connections, [groupOutput], connects)
            this.$set(this.getContext.nodes[groupOutput], 'overwrittenNodeType', outputNames)
          }

      }else{
        if( type === 'ungroup'){
          this.$set(this.getContext.connections, [groupOutput], {})
          this.$set(this.getContext.nodes[groupOutput], 'overwrittenNodeType', {'outputNames': [''], 'outputs': ['main']}) 
        }
      }
      this.workWithGroup = false
    },

    // add connect between group and parent node
    group(connect){
      this.workWithGroup = true
      this.setGroupConnect(connect.parents,  connect.id, connect.endNodeId, connect.groupOutput, 'group')
    },
    
    // add connect between ungroup and parent node
    unGroup(connect){
      this.workWithGroup = true
      setTimeout(() => {
        this.setGroupConnect(connect.parents,  connect.startGroupId, connect.endNodeId, connect.endGroupId, 'ungroup')
      }, 1)
    },

    async setEnterNode(nodeId){
      this.loadEnterNode = true
        await this.$store.dispatch('node/setEnterNode', nodeId)
        const enterNode = this.$store.getters["node/enterNode"]

        this.editorData.enterNode = enterNode
        this.$store.dispatch('processes/setEnterNode', enterNode)
      this.loadEnterNode = false
    },

    /**
     * delete last item in historyAction and cancel last action
     */
    stepBefore(){
      this.historyAction.pop()
    },

    /**
     * on each item of historyAction sending request for backend
     */
    changedPrecess(){
      if(!this.historyAction.length) return

      this.historyAction.forEach(action => {
        if(action.deleteNode) {
          this.$store.dispatch('node/deleteNode', action.deleteNode)
          this.$store.dispatch('processes/deleteConnection', {processId: this.selectedProcess, dataConnection: {nodeFrom: action.nodeFrom, nodeTo: action.deleteNode}})
        }
        else this.$store.dispatch('processes/deleteConnection', {processId: this.selectedProcess, dataConnection: action.deleteConnection})

        this.historyAction = []
      })
    }
  },

  beforeDestroy(){
    EventBus.$off('updatePresetIdOfEnterNode')
  }
}
</script>

<style scoped lang="scss">
.business-process {
  &__graph {
    position: relative;
  }

  @media(max-width: 630px) {
    &__proc {
      flex-direction: column;
      align-items: center;
    }
  }
}
.errors, .warnings{
  z-index: 10;
  margin-left: 10px;
  p, li{
    position: relative;
    z-index: 10;
  }
}
.v-tooltip__content{
  top: 174px !important;
  z-index: 110 !important;
}
ul{
  padding-left: 0;
}

.save-btn{
  width: 34px;
  height: 34px;
  cursor: pointer;
  transition: .2s all;

  &:hover{
    transition: .2s all;
    opacity: .8;
  }
}

.save-btn-enter-active, .save-btn-leave-active {
  transition: opacity .2s
}
.save-btn-enter, .save-btn-leave-to {
  opacity: .3
}
</style>
